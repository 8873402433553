import React from 'react';
import { Helmet } from 'react-helmet';

const shouldRenderAdobeAnalytics = (value: string) => {
  const normalizedValue = value?.trim().toUpperCase();
  return normalizedValue !== 'DISABLED';
};

const getScriptSrcOrValue = (input: string) => {
  const scriptTagPattern = /<script\s+src="([^"]+)"[^>]*>\s*<\/script>/;
  const match = input?.match(scriptTagPattern);

  if (match) {
    return match[1];
  }
  return input;
};

export const AdobeAnalytics = (): JSX.Element | null => {
  const scriptSource = getScriptSrcOrValue(process.env.GATSBY_ADOBE_ANALYTICS);
  const render = shouldRenderAdobeAnalytics(scriptSource);

  if (!render) {
    return null;
  }

  return (
    <Helmet>
      <script src={scriptSource} async />
    </Helmet>
  );
};
